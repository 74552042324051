import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import get from "lodash.get";

import Container from "../styles/Container";

const Wrapper = styled.div`
  padding-top: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 50px;
  }
`;

const Heading = styled.h1`
  margin-bottom: 37px;
  font-size: 18px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    font-size: 30px;
    line-height: 42px;
  }
`;

const NetworksContainer = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const Network = styled.div`
  margin-bottom: 37px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 50px;
    width: calc(50% - 27px);
    :nth-child(2n + 1) {
      margin-right: 27px;
    }
    :nth-child(2n) {
      margin-left: 27px;
    }
  }
`;

const LogoContainer = styled.div`
  /* padding: 40px; */
  padding-left: 40px;
  padding-right: 40px;
  background-color: ${(p) => p.theme.lightBlue};
  margin-bottom: 21px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 600px) {
    height: 190px;
    padding-left: 80px;
    padding-right: 80px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 26px;
  }
`;

const Logo = styled.img`
  width: 100%;
  object-fit: contain;
  max-width: 280px;
  max-height: 60px;
  @media (min-width: 600px) {
    max-width: 400px;
    max-height: 120px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: 100%;
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 26px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 16px;
    line-height: 30px;
  }
`;

const LearnMore = styled(Link)`
  font-size: 14px;
  line-height: 26px;
  font-weight: 700;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 16px;
    line-height: 30px;
  }
  :hover {
    text-decoration: underline;
  }
`;

const NetworksList = ({ heading, networks, productions }) => {
  return (
    <Container>
      <Wrapper>
        {heading && <Heading>{heading}</Heading>}
        {networks && (
          <NetworksContainer>
            {networks.map((network, index) => {
              return (
                <Network key={index}>
                  {network.network.document.data.logo && (
                    <Link to={`/network/${network.network.document.uid}`}>
                      <LogoContainer>
                        <Logo
                          src={network.network.document.data.logo.url}
                          alt={network.network.document.data.logo.alt}
                        />
                      </LogoContainer>
                    </Link>
                  )}
                  {network.network.document.data.short_description && (
                    <>
                      <Description>
                        {network.network.document.data.short_description}
                      </Description>
                      <LearnMore
                        to={`/network/${network.network.document.data.uid}`}
                      >
                        Learn More
                      </LearnMore>
                    </>
                  )}
                </Network>
              );
            })}
          </NetworksContainer>
        )}
        {productions && (
          <NetworksContainer>
            {productions.map((production, index) => {
              return (
                <Network key={index}>
                  {production.production.document.data.logo && (
                    <Link
                      to={
                        production.production.type === "distribution"
                          ? `/distribution/${production.production.document.uid}`
                          : `/productions/${production.production.document.uid}`
                      }
                    >
                      <LogoContainer>
                        <Logo
                          src={production.production.document.data.logo.url}
                          alt={production.production.document.data.logo.alt}
                        />
                      </LogoContainer>
                    </Link>
                  )}
                  {production.production.document.data.short_description && (
                    <>
                      <Description>
                        {production.production.document.data.short_description}
                      </Description>
                      <LearnMore
                        to={
                          production.production.type === "distribution"
                            ? `/distribution/${production.production.document.uid}`
                            : `/productions/${production.production.document.uid}`
                        }
                      >
                        Learn More
                      </LearnMore>
                    </>
                  )}
                </Network>
              );
            })}
          </NetworksContainer>
        )}
      </Wrapper>
    </Container>
  );
};

export default NetworksList;
