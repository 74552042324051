import React from "react";
import { graphql } from "gatsby";

import HeroHeading from "../components/HeroHeading";
import ViewNext from "../components/ViewNext";
import NetworksList from "../components/NetworksList";
import HelmetInfo from "../components/HelmetInfo";

const Distribution = ({ data }) => {
  const { heading, subheading, productions } =
    data.prismicDistributionIndex.data;

  return (
    <>
      <HelmetInfo page_data={data.prismicDistributionIndex.data} />
      <HeroHeading heading={heading} subheading={subheading} />
      <NetworksList productions={productions} />
      <ViewNext
        link={
          productions[0].production.type === "distribution"
            ? `/distribution/${productions[0].production.document.uid}`
            : `/productions/${productions[0].production.document.uid}`
        }
        linkText={productions[0].production.document.data.title}
        borderTop
      />
    </>
  );
};

export default Distribution;

export const query = graphql`
  query ProductionIndexQuery {
    prismicDistributionIndex {
      data {
        heading
        subheading
        productions {
          production {
            type
            document {
              ... on PrismicProduction {
                uid
                data {
                  logo {
                    url
                    alt
                  }
                  title
                  short_description
                }
              }
              ... on PrismicDistribution {
                uid
                data {
                  logo {
                    url
                    alt
                  }
                  title
                  short_description
                }
              }
            }
          }
        }
      }
    }
  }
`;
